<template>
  <div class="page flex-col">
    <div class="group_1 flex-col justify-between">
      <div class="block_5 flex-col">
        <div class="group_20">
          <div class="f-w1200 f-htop flex-row justify-between">
            <div class="group_21 flex-col">
              <div class="text-wrapper_3">
                <div class="text_22">用模型驱动开发，</div>
                <div class="text_23">致力于成为您长期的技术合作伙伴</div>
              </div>
              <div class="paragraph_1">
                我们负责领域建模，产出领域模型和产品原型；帮您遴选开发团队完成应用层开发；我们对开发过程实施监理，对最终结果负责。
                <br />
                在后续运营阶段，我们持续提供运营支持服务，包括产品策划、模型迭代、系统运维、开发监理等，致力于成为您长期的技术合伙人。
              </div>
              <div class="text-wrapper_4 flex-col" @click="dialogVisible = true"><span class="text_24">合作洽谈</span></div>
            </div>
            <img class="image_9" referrerpolicy="no-referrer"
              src="@/assets/gxt.png" />
          </div>
        </div>
        <div class="f-w1200">
          <div class="f-text_9">
            合作流程
          </div>
          <div class="group_17 flex-row">
            <div class="image-text_5 flex-col justify-between">
              <img class="image_5" referrerpolicy="no-referrer"
                src="./assets/img/psrfiz923dd8iwuyu0dl2mdp3ibbn6rmzi36bcb6a97-4ced-400b-82ad-50e400f9b389.png" />
              <span class="text-group_1">1.前期服务</span>
            </div>
            <img class="image_2" referrerpolicy="no-referrer"
              src="./assets/img/ps3ecwpu6jczdf3uqr3jbm9toul6bsnei60b94d472-005c-42e5-aeb0-d0454304a022.png" />
            <div class="image-text_6 flex-col justify-between">
              <img class="image_6" referrerpolicy="no-referrer"
                src="./assets/img/psapyn83om8tvgrxeqque9nb9bq1we6oy6n409a512e-1ba5-44a3-8737-23ad9c000968.png" />
              <span class="text_10">2.领域建模</span>
            </div>
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/psrswxe5rxur8u2lz35cidthhem5mki8ovbbe656cc-6bbb-418c-8e9f-918b05d704fd.png" />
            <div class="image-text_7 flex-col justify-between">
              <img class="image_7" referrerpolicy="no-referrer"
                src="./assets/img/pstw7va32t9mcbnyks29q3vz24qdyi19dn73116724-e6cc-4208-8294-007e9070e7ff.png" />
              <span class="text-group_2">3.开发团队遴选</span>
            </div>
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psxt85ti3zfkky5p96xrcknoslve917yif3f57e2aa-85da-41b5-b314-6cce3efa9166.png" />
            <div class="image-text_8 flex-col justify-between">
              <img class="image_8" referrerpolicy="no-referrer"
                src="./assets/img/ps9papte3x2djz9gyfbz1zsxvxv6ztoxu896c10feb-9702-4115-90d9-625b028baade.png" />
              <span class="text_11">4.应用层开发</span>
            </div>
          </div>
          <div class="group_18 flex-row justify-between">
            <div class="text-group_11 flex-col">
              <span class="text_12">
                制作功能清单，确定封顶价，确保比标杆企业报价低30%-50%
              </span>
              <span class="text_13">交付：功能清单、封顶价、工期</span>
            </div>
            <div class="text-group_12 flex-col">
              <span class="text_14">我们负责领域建模和产品原型设计</span>
              <span class="text_15">交付：领域模型、产品原型</span>
            </div>
            <div class="text-group_13 flex-col justify-between">
              <span class="text_16">
                我们帮你遴选开发团队，如果总价超出封顶价，超出部分由我们支付
              </span>
              <span class="text_17">方案：支持4种类型的开发团队，<span style="text-decoration: underline; cursor: pointer;" @click="dialogVisible = true">详询客服</span></span>
            </div>
            <div class="text-group_14 flex-col justify-between">
              <span class="text_18">
                开发团队基于领域模型开发最终产品，我们负责全程监理，对最终结果负责
              </span>
              <span class="text_19">交付：最终产品</span>
            </div>
          </div>
          <div class="group_19 flex-row justify-between">
            <div class="text-wrapper_1 flex-col" @click="goToAbout('/experience')"><span class="text_20">免费体验</span>
            </div>
            <div class="text-wrapper_2 flex-col" @click="goToAbout('/modeling')"><span class="text_21">详细了解领域建模</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" :show-close="false" width="850px">
      <div class="f-box">
        <img class="f-gb" @click="dialogVisible = false" src="@/assets/gb.png" alt="">
        <img class="f-kf" src="@/assets/kf.png" alt="">
        <div class="f-left">
          <div class="f-title">官方客服</div>
          <div class="f-text-box">
            <div class="f-code">
              <img src="@/assets/ewm.png" alt="">
              <div class="f-right-text">
                <div class="f-dh">
                  <img src="@/assets/pswf87j353aab756ltx8df4fbof24dh8hqa8647aeec-d037-4bbc-b7a7-4d02751c6ca3.png"
                    alt="">
                  <span>177 6400 7731</span>
                </div>
                <div class="f-tes">周一至周五 9:00~18:00</div>
                <div class="f-text-data">扫码添加官方客服微信</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  mounted() {
    LA.track('hzms_05');
  },
  methods: {
    goToAbout(url) {
      this.$router.push(url);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />