<template>
  <div class="page flex-col">
    <div class="box_1">
      <div class="block_1 flex-col">
        <div class="group_5">
          <div class="section_5 flex-col">
            <span class="text_17">大厂产品的格调，你想要吗？</span>
            <div class="text-wrapper_1">
              <span class="text_18">领域建模，用平常的成本</span>
              <span class="text_19">定制</span>
              <span class="text_20">高品质的</span>
              <span class="text_21">软件</span>
            </div>
            <div class="box_2 flex-row justify-between">
              <div class="text-wrapper_2 flex-col" @click="scrollToAnchor('section1')"><span class="text_22">品质承诺</span>
              </div>
              <div class="text-wrapper_3 flex-col" @click="scrollToAnchor('section2')"><span class="text_23">价格承诺</span>
              </div>
            </div>
          </div>
          <div class="flex-row f-list f-w1200">
            <div class="section_1 flex-col">
              <img class="image_2" referrerpolicy="no-referrer"
                src="./assets/img/ps9u1i23lk5gjnoyhocophmanah2evlxuqfa0a04f0-3cf4-46b1-bf94-3bfb61611f30.png" />
              <span class="text_9">微信开发</span>
              <span class="text_10">小程序&nbsp;&nbsp;&nbsp;&nbsp;公众号</span>
            </div>
            <div class="section_2 flex-col">
              <img class="image_3" referrerpolicy="no-referrer"
                src="./assets/img/psxwbycvjzi36i1q89ycwgcai029t93fec46f59abb-9247-403d-8386-55c9ca542fe1.png" />
              <span class="text_11">网站开发</span>
              <span class="text_12">PC网站&nbsp;&nbsp;&nbsp;&nbsp;手机网站</span>
            </div>
            <div class="section_3 flex-col">
              <img class="image_4" referrerpolicy="no-referrer"
                src="./assets/img/psfnbrmc34xsdb73xasti8ij7p3neddkrus7df98e4e-378b-4e51-9dbc-e995d0327425.png" />
              <span class="text_13">APP开发</span>
              <span class="text_14">安卓&nbsp;&nbsp;&nbsp;&nbsp;IOS&nbsp;&nbsp;&nbsp;&nbsp;鸿蒙</span>
            </div>
            <div class="section_4 flex-col">
              <img class="image_5" referrerpolicy="no-referrer"
                src="./assets/img/ps946jnt7qzc2ek37pvon77dsnxapqjj8490886d9e-b693-4641-ab57-3fd31ac42a9a.png" />
              <span class="text_15">传统软件开发</span>
              <span class="text_16">
                Windows&nbsp;&nbsp;&nbsp;&nbsp;Mac&nbsp;&nbsp;&nbsp;&nbsp;工控机&nbsp;&nbsp;&nbsp;&nbsp;嵌入式
              </span>
            </div>
          </div>
        </div>
        <div class="group_6 flex-col" id="section1">
          <span class="text_24">大厂产品有哪些高贵品质？</span>
          <span class="text_25">这是我们对品质的承诺，写入合同，终身负责</span>
          <div class="box_3 flex-row justify-between">
            <div class="box_4 flex-col">
              <div class="image-text_1 flex-row justify-between">
                <span class="text-group_1">业务准确性</span>
                <img class="label_2" referrerpolicy="no-referrer"
                  src="./assets/img/ps1gl5i3el679wlna06xh8k0ojrxudx6ce2fc3b98-1a1d-473b-ba5a-184e5ecaf9b9.png" />
              </div>
              <span class="text_26">
                业务理解全面、准确、有深度，确保不存在深层业务逻辑冲突
              </span>
            </div>
            <div class="box_5 flex-col">
              <div class="text-wrapper_4 flex-row"><span class="text_27">可扩展性</span></div>
              <div class="text-wrapper_5 flex-row">
                <span class="text_28">功能扩展</span>
                <span class="text_29">性能扩展</span>
                <span class="text_30">服务扩展</span>
              </div>
              <div class="text-wrapper_6 flex-row">
                <span class="text_31">
                  无须重构，仅通过增加程序模块，就可以满足未来的业务扩展需求
                </span>
                <span class="text_32">
                  无须重构，仅通过增加服务器，就可以满足未来用户量和数据量增长需求
                </span>
                <span class="text_33">
                  无须重构，仅通过增加插件，就可以更换第三方服务商（如短信通道）
                </span>
              </div>
            </div>
          </div>
          <div class="list_1 flex-row">
            <div class="text-wrapper_7 flex-col" :style="{ background: item.lanhuBg0 }"
              v-for="(item, index) in loopData0" :key="index">
              <span class="text_34" v-html="item.lanhutext0"></span>
              <span class="text_35" v-html="item.lanhutext1"></span>
            </div>
          </div>
        </div>
        <div class="group_7 flex-col" id="section2">
          <div class="box_6 flex-col">
            <div class="box_7 flex-row">
              <div class="text-wrapper_8 flex-col">
                <span class="text_36">首版开发</span>
                <span class="text_37">找3-5家知名软件公司询价，在平均价的基础上降低30-50%作为整体封顶价</span>
              </div>
            </div>
            <div class="box_8 flex-row justify-between">
              <div class="group_8 flex-row justify-between">
                <div class="text-wrapper_9 flex-col justify-between">
                  <span class="text_38">需求变更</span>
                  <span class="text_39">修改需求的价格在其它软件公司报价的基础上降低50%</span>
                </div>
              </div>
              <div class="group_9 flex-col">
                <span class="text_42">价格承诺</span>
                <div class="text-wrapper_10 flex-col"><span class="text_43"
                    @click="goToAbout('/modeling', 'sylj_03')">了解领域建模</span></div>
                <div class="text-wrapper_11 flex-col"><span class="text_44"
                    @click="goToAbout('/experience', 'symf_04')">免费体验</span></div>
              </div>
            </div>
            <div class="box_9 flex-row">
              <div class="group_10 flex-row">
                <div class="text-wrapper_12 flex-col justify-between">
                  <span class="text_45">版本迭代</span>
                  <span class="text_46">版本迭代的价格在其它软件公司报价的基础上降低50%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_13 flex-col"><span class="text_49">莲通领域，定义未来 鄂ICP-备19018512号-7</span></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0:
            'url(' + require('./assets/img/yhty.png') + ') 100% no-repeat',
          lanhutext0: '用户体验',
          lanhutext1:
            '以领域模型为基础设计产品原型，打磨细节，以原型作为核心验收标准'
        },
        {
          lanhuBg0:
            'url(' + require('./assets/img/kwhx.png') + ') 100% no-repeat',
          lanhutext0: '可维护性',
          lanhutext1: '提供符合UML标准的设计文档，代码规范，注释详细'
        },
        {
          lanhuBg0:
            'url(' + require('./assets/img/bfzc.png') + ') 100% no-repeat',
          lanhutext0: '并发支持',
          lanhutext1: '应对大流量冲击时支持分布式部署，确保各节点不发生冲突'
        }
      ],
      constants: {}
    };
  },
  mounted() {
    if (window.LA) {
      console.log('51LA SDK 加载成功');
    } else {
      console.error('51LA SDK 未加载');
    }
  },
  methods: {
    goToAbout(url, track) {
      LA.track(track);

      this.$router.push(url);
    },
    scrollToAnchor(anchor) {
      LA.track(anchor === 'section1' ? 'syp_01' : 'H5JG_02');

      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />