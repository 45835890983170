import Vue from 'vue'
import VueRouter from 'vue-router'
import strength from '../views/strength/index.vue' // 品牌实力
import cooperation from '../views/cooperation/index.vue' // 合作
import home from '../views/home/index.vue' // 首页
import modeling from '../views/modeling/index.vue' // 领域建模
import caseview from '../views/case/index.vue' // 工程案例
import modelingTeam from '../views/modelingTeam/index.vue' // 建模团队
import experience from '../views/experience/index.vue' // 免费体验

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/strength',
    name: 'strength',
    component: strength
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation
  },
  {
    path: '/index',
    name: 'index',
    component: home
  },
  {
    path: '/modeling',
    name: 'modeling',
    component: modeling
  },
  {
    path: '/case',
    name: 'case',
    component: caseview
  },
  {
    path: '/modelingTeam',
    name: 'modelingTeam',
    component: modelingTeam
  },
  {
    path: '/experience',
    name: 'experience',
    component: experience
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
