<template>
  <div class="page">
    <div class="group_1">
      <div class="section_20 flex-col">
        <div class="box_2">
          <div class="f-w1200 flex-col">
            <div class="text-wrapper_24">欢迎体验</div>
            <div class="text-wrapper_25">
              <span class="paragraph_1">
                您可以从拟开发软件中选取一个最复杂的业务场景，
                <br />
                我们针对该场景建模，并基于模型设计产品原型让您体验产品的最终效果。
                <br />
                最后，我们的领域分析师会针对模型要点进行讲解（视频或线下）。
              </span>
            </div>
            <div class="group_23 flex-row justify-between">
              <div class="group_3 flex-col">
                <div class="image-text_22 flex-col justify-between">
                  <img class="label_20" referrerpolicy="no-referrer"
                    src="./assets/img/psf0g0l0f42tr57tmibg6d6v4luywscbl3a4a812810-c0ea-45f6-898c-305363b395e2.png" />
                  <span class="text_10">示例</span>
                </div>
                <span class="text_11">客户真实需求截选，可供参考</span>
                <div style="display: flex; padding-top: 13px; margin: 0 auto;">
                  <div class="text-wrapper_3 f-text-wrapper_3 flex-col" @click="scrollToAnchor('section1')"><span
                      class="text_12">文字版</span></div>
                  <div class="text-wrapper_3 flex-col" @click="aliplayers"><span
                      class="text_12">视频版</span></div>
                </div>
              </div>
              <div class="group_4 flex-col">
                <div class="image-text_23 flex-col justify-between">
                  <img class="label_21" referrerpolicy="no-referrer"
                    src="./assets/img/psnumoi8rrv0duwie17id1qhwmjnmlf2wudf7dae97-c2b0-4626-a517-799be44ed104.png" />
                  <span class="text_13">免费体验</span>
                </div>
                <span class="text_14">客服将安排领域分析师、产品经理与您对接</span>
                <div class="image-text_24 flex-col justify-between">
                  <div class="text-wrapper_4 flex-col" @click="dialogVisible = true"><span
                      class="text_15">联系客服开始体验</span></div>
                  <span class="text-group_1">（周一至周五&nbsp;9:00~18:00）</span>
                </div>
              </div>
              <div class="group_5 flex-col">
                <div class="image-text_25 flex-col justify-between">
                  <img class="label_22" referrerpolicy="no-referrer"
                    src="./assets/img/ps9zppo2w3tmifatxn7jiav50so5dxjiva1r9e58c071-8615-4887-a581-dcfa18c076af.png" />
                  <span class="text_16">领域建模</span>
                </div>
                <span class="text_17">用平常的成本定制高品质的软件</span>
                <div class="text-wrapper_5 flex-col" @click="goToAbout('/modeling')"><span class="text_18">了解领域建模</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img class="image_4" referrerpolicy="no-referrer"
          src="./assets/img/ps5t6heaofi57olgadrxinw9demxwz2gw2431c40abf-921d-41b9-ab6d-15e7caaf9b50.png" />
      </div>
      <div class="group_15" id="section1">
        <div class="f-w1200 flex-col">
          <div class="group_24 flex-row">
            <div class="image-text_26 flex-row justify-between">
              <span class="text-group_3">审批流</span>
              <img class="image_5" referrerpolicy="no-referrer"
                src="./assets/img/ps1f3m1aled8xo4pfqckzm28a5rad8oq8861be5e45-154a-4db2-ad51-e60f78060b4f.png" />
            </div>
          </div>
          <div class="text-wrapper_26 flex-row">
            <span class="paragraph_2">
              本示例取自一个企业内部管理系统，系统包含组织架构管理、审批流程管理、数据
              <br />
              大屏、员工培训、员工在线考试等功能
            </span>
          </div>
          <div class="group_25 flex-row">
            <div class="group_16 flex-row" @click="aliplayers">
              <div class="image-text_27 flex-row justify-between">
                <img class="label_23" referrerpolicy="no-referrer"
                  src="./assets/img/psq25eqppvmukhc89k4rhdhdynh7rvak199dcf67b0-f6eb-4bea-97c2-5631c00eb217.png" />
                <span class="text-group_4">观看视频版</span>
              </div>
            </div>
          </div>
          <div class="group_26 flex-row justify-between">
            <div class="block_14 flex-col">
              <div class="section_21 flex-row justify-between">
                <img class="label_24" referrerpolicy="no-referrer"
                  src="./assets/img/ps9t7jezrywc2vvpijqow8dawkxjox7ujd09c9a278-08cd-4571-824a-0274cd65eb5b.png" />
                <div class="group_27 flex-col justify-between">
                  <span class="text_34">甲方</span>
                  <div class="text-wrapper_10 flex-col">
                    <span class="text_35">
                      我的系统里需要能够自定义审批流程，每个审批可以自由设置审批层级和每一层负责审批的人
                    </span>
                  </div>
                </div>
              </div>
              <div class="section_22 flex-row">
                <div class="text-wrapper_11 flex-col">
                  <span class="text_36">
                    审批时填写的那些选项，也要能够自定义，要支持单选、多选、输入、文件上传这些功能
                  </span>
                </div>
              </div>
              <div class="section_23 flex-row">
                <div class="image-text_28 flex-row justify-between">
                  <span class="text-group_5">Obase&nbsp;Lotus</span>
                  <img class="label_25" referrerpolicy="no-referrer"
                    src="./assets/img/pslnjsmvvqvl9xumbxsg3ngzvk3cmmtt0lfb95f9c4-7032-44b9-8c71-67abfdf7514a.png" />
                </div>
                <div class="group_17">
                  <div class="text_37">以下是根据需求产出的概念模型和产品原型：</div>
                  <div class="f-list-img">
                    <img class="f-iamge-group" src="./assets/image1/P1.png" alt="">
                    <img class="f-iamge-group" src="./assets/image1/P2.png" alt="">
                    <div class="block_15 flex-col">
                      <img class="label_26" referrerpolicy="no-referrer"
                        src="./assets/img/psqq2quiy5zzfh0xsf1nngwxjl6x4oqef16598007-e3ff-4e87-b9e6-cdef545cd234.png" />
                      <div class="text-wrapper_12 flex-col"><span class="text_38" @click="viewBtn(1)">点击查看完整方案</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section_24 flex-row justify-between">
                <img class="label_7" referrerpolicy="no-referrer"
                  src="./assets/img/pscni9ml7da9cxo97fkpvs5esmkbsiqpb99b8e20b10-7287-48b2-a3b4-9dc2bf9a81f8.png" />
                <div class="group_28 flex-col justify-between">
                  <span class="text_39">甲方</span>
                  <div class="text-wrapper_13 flex-col">
                    <span class="text_40">
                      加个功能，每个审批节点要能绑定多个负责的人，还要能设置是全都要审核，还是其中一个人审核就行，最好可以自定义至少几人审核就算通过
                    </span>
                  </div>
                </div>
              </div>
              <div class="section_23 flex-row">
                <div class="image-text_28 flex-row justify-between">
                  <span class="text-group_5">Obase&nbsp;Lotus</span>
                  <img class="label_25" referrerpolicy="no-referrer"
                    src="./assets/img/pslnjsmvvqvl9xumbxsg3ngzvk3cmmtt0lfb95f9c4-7032-44b9-8c71-67abfdf7514a.png" />
                </div>
                <div class="group_17">
                  <div class="text_37">已更新概念模型和产品原型：</div>
                  <div class="f-list-img">
                    <img class="f-iamge-group" src="./assets/image2/P1.png" alt="">
                    <img class="f-iamge-group" src="./assets/image2/P2.png" alt="">
                    <div class="block_15 flex-col">
                      <img class="label_26" referrerpolicy="no-referrer"
                        src="./assets/img/psqq2quiy5zzfh0xsf1nngwxjl6x4oqef16598007-e3ff-4e87-b9e6-cdef545cd234.png" />
                      <div class="text-wrapper_12 flex-col"><span class="text_38" @click="viewBtn(2)">点击查看完整方案</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section_23 flex-row">
                <div class="image-text_28 flex-row justify-between">
                  <span class="text-group_5">Obase&nbsp;Lotus</span>
                  <img class="label_25" referrerpolicy="no-referrer"
                    src="./assets/img/pslnjsmvvqvl9xumbxsg3ngzvk3cmmtt0lfb95f9c4-7032-44b9-8c71-67abfdf7514a.png" />
                </div>
                <div class="group_17">
                  <div class="text_37 f-texts3">
                    一些建议：在创建审批项目时，维护表单项是一个比较复杂的工作，实际上很多审批项目的表单项有部分是一样的，我们可以引入“审批类型”，在审批类型内维护这个类型内通用的表单项，另外在各个审批项目中只需维护特有的表单项。可参考如下概念模型和产品原型：
                  </div>
                  <div class="f-list-img f-list2">
                    <img class="f-iamge-group" src="./assets/image3/P1.png" alt="">
                    <img class="f-iamge-group" src="./assets/image3/P2.png" alt="">
                    <div class="block_15 flex-col">
                      <img class="label_26" referrerpolicy="no-referrer"
                        src="./assets/img/psqq2quiy5zzfh0xsf1nngwxjl6x4oqef16598007-e3ff-4e87-b9e6-cdef545cd234.png" />
                      <div class="text-wrapper_12 flex-col"><span class="text_38" @click="viewBtn(3)">点击查看完整方案</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section_26 flex-row justify-between">
                <img class="label_10" referrerpolicy="no-referrer"
                  src="./assets/img/psxjiyhxe24ea2jp1hczewnhh14miiyj448782caf4a-a805-4197-9802-fcfa39272866.png" />
                <div class="box_32 flex-col justify-between">
                  <span class="text_43">甲方</span>
                  <div class="text-wrapper_15 flex-col">
                    <span class="text_44">
                      这个建议很不错！能极大减少我们前期使用系统的工作量，从功能上来看也更完善了。
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="f-kf-exp">
      <el-dialog :visible.sync="dialogVisible" :show-close="false" width="850px">
        <div class="f-box">
          <img class="f-gb" @click="dialogVisible = false" src="@/assets/gb.png" alt="">
          <img class="f-kf" src="@/assets/kf.png" alt="">
          <div class="f-left">
            <div class="f-title">官方客服</div>
            <div class="f-text-box">
              <div class="f-code">
                <img src="@/assets/ewm.png" alt="">
                <div class="f-right-text">
                  <div class="f-dh">
                    <img src="@/assets/pswf87j353aab756ltx8df4fbof24dh8hqa8647aeec-d037-4bbc-b7a7-4d02751c6ca3.png"
                      alt="">
                    <span>177 6400 7731</span>
                  </div>
                  <div class="f-tes">周一至周五 9:00~18:00</div>
                  <div class="f-text-data">扫码添加官方客服微信</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <div class="f-box">
      <el-dialog title="项目示例" :visible.sync="dialogVisible2" :before-close="handleClose" width="1000px">
        <div class="prism-player" id="J_prismPlayer"></div>
      </el-dialog>
    </div>
    <div class="f-box-image">
      <el-dialog :show-close="false" :visible.sync="dialogVisible3" width="1080px">
        <div class="f-image-list" v-if="index === 1">
          <img src="./assets/image1/P1.png" alt="">
          <img src="./assets/image1/P2.png" alt="">
          <img src="./assets/image1/P3.png" alt="">
          <img src="./assets/image1/P4.png" alt="">
          <img src="./assets/image1/P5.png" alt="">
          <img src="./assets/image1/P6.png" alt="">
          <img src="./assets/image1/P7.png" alt="">
          <img src="./assets/image1/P8.png" alt="">
          <img src="./assets/image1/P9.png" alt="">
        </div>
        <div class="f-image-list" v-if="index === 2">
          <img src="./assets/image2/P1.png" alt="">
          <img src="./assets/image2/P2.png" alt="">
          <img src="./assets/image2/P3.png" alt="">
          <img src="./assets/image2/P4.png" alt="">
        </div>
        <div class="f-image-list" v-if="index === 3">
          <img src="./assets/image3/P1.png" alt="">
          <img src="./assets/image3/P2.png" alt="">
          <img src="./assets/image3/P3.png" alt="">
          <img src="./assets/image3/P4.png" alt="">
        </div>
        <div class="f-col" @click="dialogVisible3 = false">
          <img src="./assets/gb.png" alt="">
          <span>关闭方案</span>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      player: null,
      index: 1,
      dialogVisible3: false
    };
  },
  mounted() {

  },
  methods: {
    viewBtn(index) {
      this.index = index;
      this.dialogVisible3 = true;
    },
    // eslint-disable-next-line no-undef 
    aliplayers() {
      let _this = this;
      axios.get('https://api.obase.vip/api/v1/video/approval-process-example-section/aliyun-play-auths')
        .then(res => {
          _this.dialogVisible2 = true;
          let data = res.data;
          setTimeout(() => {
            _this.player = new Aliplayer({
              id: "J_prismPlayer",
              autoplay: true,
              width: "960px",
              height: "540px",
              vid: data.data.videoId,
              playauth: data.data.playAuth,
              cover: data.data.coverURL,
              license: {
                domain: "obase.vip",
                key: "9PdeWqfeMBDmX0Vg7315f547fe2904a9e91d9b77be20421df"
              }
            });
          }, 120);
        })
    },
    handleClose() {
      if (this.player) {
        this.player.dispose();
      }
      this.dialogVisible2 = false;
    },
    goToAbout(url) {
      this.$router.push(url);
    },
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          anchorElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
::v-deep .f-box-image .el-dialog {
  border-radius: 10px;
}

::v-deep .f-kf-exp .el-dialog__header {
  padding: 0;
}

::v-deep .f-box-image .el-dialog__header {
  padding: 0
}

::v-deep .f-box .el-dialog__header {
  padding-bottom: 0;
}

::v-deep .f-box .el-dialog {
  border-radius: 10px;
}

::v-deep .f-box .el-dialog__headerbtn .el-dialog__close {
  font-size: 28px;
  color: #333;
}

::v-deep .f-box .el-dialog__title {
  font-size: 22px;
  font-weight: 600;
}
</style>