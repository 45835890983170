<template>
    <div>
        <div class="f-box_6">
            <img class="image_1" referrerpolicy="no-referrer"
                src="./logo.png" />
            <div class="flex-row f-ul">
                <span class="f-text" :class="{ 'f-state': state == 'index' }" @click="changeState('index')">首页 <div
                        class="f-l"></div></span>
                <span class="f-text" :class="{ 'f-state': state == 'modeling' }" @click="changeState('modeling')">领域建模
                    <div class="f-l"></div>
                </span>
                <span class="f-text" :class="{ 'f-state': state == 'experience' }"
                    @click="changeState('experience')">免费体验
                    <div class="f-l"></div>
                </span>
                <span class="f-text" :class="{ 'f-state': state == 'cooperation' }"
                    @click="changeState('cooperation')">合作模式 <div class="f-l"></div></span>
                <span class="f-text" :class="{ 'f-state': state == 'strength' }" @click="changeState('strength')">技术实力
                    <div class="f-l"></div>
                </span>
                <span class="f-text" :class="{ 'f-state': state == 'modelingTeam' }"
                    @click="changeState('modelingTeam')">建模团队 <div class="f-l"></div></span>
                <span class="f-text" :class="{ 'f-state': state == 'case' }" @click="changeState('case')">经典案例 <div
                        class="f-l"></div></span>
            </div>
            <div class="f-right" @click="dialogVisible = true">
                <div class="image-wrapper_1 flex-col">
                    <img class="thumbnail_1" referrerpolicy="no-referrer"
                        src="./pswf87j353aab756ltx8df4fbof24dh8hqa8647aeec-d037-4bbc-b7a7-4d02751c6ca3.png" />
                </div>
                <span class="text_8">联系我们</span>
            </div>
        </div>
        <div class="f-heig_6"></div>


        <el-dialog :visible.sync="dialogVisible" :show-close="false" width="850px">
            <div class="f-box">
                <img class="f-gb" @click="dialogVisible = false" src="@/assets/gb.png" alt="">
                <img class="f-kf" src="@/assets/kf.png" alt="">
                <div class="f-left">
                    <div class="f-title">官方客服</div>
                    <div class="f-text-box">
                        <div class="f-code">
                            <img src="@/assets/ewm.png" alt="">
                            <div class="f-right-text">
                                <div class="f-dh">
                                    <img src="@/assets/pswf87j353aab756ltx8df4fbof24dh8hqa8647aeec-d037-4bbc-b7a7-4d02751c6ca3.png"
                                        alt="">
                                    <span>177 6400 7731</span>
                                </div>
                                <div class="f-tes">周一至周五 9:00~18:00</div>
                                <div class="f-text-data">扫码添加官方客服微信</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'Header',
    data() {
        return {
            state: 'index',
            dialogVisible: false
        }
    },
    watch: {
        '$route.name': function (val) {
            this.state = val;
            this.scrollToTop()
        }
    },
    mounted() {
        this.state = sessionStorage.getItem('routerName') || 'index';
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        changeState(name) {
            this.state = name;
            sessionStorage.setItem('routerName', name);
            this.$router.push({ name });
        }
    },
}
</script>
<style scoped lang="css">
::v-deep .el-dialog__header {
    padding: 0;
}
.f-heig_6 {
    height: 72px;
    width: 100%;
}

.f-box_6 {
    top: 0;
    left: 0;
    z-index: 111;
    position: fixed;
    padding: 0 60px;
    border-bottom: 1px solid #eee;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
}

.f-ul {
   
}

.f-lefts {
    margin-left: 40px;
}

.f-right {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.image_1 {
    width: 188px;
    height: 36px;
}

.f-text {
    text-align: center;
    width: 120px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1);
    font-size: 16px;
    font-family: MiSans-Regular;
}

.image-wrapper_1 {
    background-color: rgba(255, 255, 255, 1);
    height: 24px;
    width: 24px;
}

.thumbnail_1 {
    width: 24x;
    height: 24px;
}

.text_8 {
    overflow-wrap: break-word;
    font-weight: 600;
    font-size: 14px;
    color: #ED5118;
    line-height: 32px;
    font-family: MiSans-Semibold;
    text-align: right;
    white-space: nowrap;
    padding-left: 7px;
    padding-bottom: 2px;
    color: rgba(237, 81, 24, 1);
}

.f-state {
    position: relative;
    font-weight: bold;
    color: rgba(237, 81, 24, 1);
}

.f-state .f-l {
    width: 52px;
    height: 3px;
    background: #EC6817;
    border-radius: 2px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -26px;
}
</style>