<template>
  <div class="page flex-col">
    <div class="box_1">
      <div class="group_9 flex-col">
        <div class="group_10 flex-col">
          <span class="text_9">1. 建模方法</span>
          <span class="paragraph_1">
            领域建模是一项极富挑战性的工作，被称为“一项不可传授的技能”，合格建模师极度稀缺。
            <br />
            经过二十年潜心研究，我们创立了一套独特的领域建模方法，建立了行业领先的领域建模能力，并且大大简化了建模师的培养过程。
          </span>
          <div class="list_2 flex-row">
            <div class="text-wrapper_14 f-bg-img1 flex-col">
              <span class="text_10">概念建模概论</span>
              <div class="f-btn" @click="watchBtn()">
                <img src="@/assets/sp_x.png" alt="">
                <span>观看视频</span>
              </div>
            </div>
            <div class="text-wrapper_14 f-bg-img2 flex-col">
              <span class="text_10">技术建模概述</span>
              <div class="f-btn" @click="watchBtn2(1)">
                <img src="@/assets/sp_x.png" alt="">
                <span>观看视频</span>
              </div>
            </div>
            <div class="text-wrapper_14 f-bg-img3 flex-col">
              <span class="text_10">知识库</span>
            </div>
          </div>
        </div>
        <div class="box_7 flex-col">
          <span class="text_11">2. 技术平台：Obase框架</span>
          <span class="text_12">
            领域模型需要全面、深刻地反映现实世界，封装业务领域中的对象体系和协作机制，因而其必定是复杂的。这样的模型在运行期间生成的对象系统异常复杂，这就对持久化与反持久化技术提出了新的挑战，现有持久化框架(如EF、Hibemate等)均无法支持。
          </span>
          <div class="box_8 flex-col">
            <div class="image-wrapper_3 flex-row">
              <img class="image_2" src="./assets/kj1.png" />
              <img class="image_2" src="./assets/kj2.png" />
              <img class="image_2" src="./assets/kj3.png" />
            </div>
            <img class="image_5" referrerpolicy="no-referrer"
              src="./assets/img/psph7x8csdt89fwok47094xb1ih1oplxf782eb86d55-3578-4b9d-9bd9-313ff2d302ce.png" />
          </div>
        </div>
        <div class="group_11 flex-col">
          <div class="text-wrapper_15 flex-col"><span class="text_14">3. 产品设计</span></div>
          <div class="box_18 flex-row justify-between">
            <div class="block_1 flex-col">
              <img class="image_13" referrerpolicy="no-referrer"
                src="./assets/img/ps8yewo3nhilj3pwchuiu6fe8qvvr5ewezd274e1f05-d363-4773-8005-4e97cf8539f4.png" />
              <span class="text_15">以领域模型为基础</span>
              <span class="text_16">
                软件产品的灵魂是业务逻辑。传统的产品设计方法对业务重视不足，以界面设计为要，软件不好用的根源在于业务理解不到位。我们把产品设计工作建基于领域模型，领域模型深刻反映业务的本质，为产品价值的实现提供了根本保障。
              </span>
            </div>
            <div class="block_2 flex-col">
              <img class="image_14" referrerpolicy="no-referrer"
                src="./assets/img/pswvqqck0l95ntaq7l6pdcmqwaufkcp2hv99af33ed-50c9-47ea-83df-73354de164cd.png" />
              <span class="text_17">与开发流程相融合</span>
              <span class="text_18">
                传统开发模式下，产品设计与软件开发相互独立，产品经理与开发团队对业务理解不一致，导致产品经理认为很简单的功能开发团队却无法实现。我们的开发模式让产品经理与开发团队基于同一领域模型工作，从根本上规避这种冲突。
              </span>
            </div>
            <div class="block_3 flex-col">
              <img class="image_15" referrerpolicy="no-referrer"
                src="./assets/img/psky99ye45r51gwk5a0kzifs8icf1gnu9bbddd4c4-ada3-484d-a0b1-c20c4dc72211.png" />
              <span class="text_19">视用户体验为命脉</span>
              <span class="text_20">
                产品成功的关键在于用户体验。传统软件开发以实现功能为主要目标。我们认为，实现功能只是基础，还需要在此基础上追求极致用户体验。我们会制作响应式产品原型，让您在开发前就能感受产品的最终形态，打磨细节，提升体验。
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="group_3 flex-col">
        <span class="text_21">4. 过程模型</span>
        <span class="paragraph_2">
          我们以领域建模方法为基础，创立了独特的软件工程过程模型，强调以概念建模为核心，并将
          <br />
          产品设计流程与技术流程有机整合在一起。
        </span>
        <div class="group_4 flex-col">
          <div class="image-text_3 flex-row justify-between">
            <img class="thumbnail_2" referrerpolicy="no-referrer"
              src="./assets/img/psgik86hfo5yq8h18r0ayforkkno6mip3g05fa6c9d-74cc-4143-a8cd-3546e991015a.png" />
            <span class="text-group_2">引入领域建模后的开发流程</span>
          </div>
          <div class="text-wrapper_16 flex-row justify-between">
            <span class="text_22">项目启动</span>
            <span class="text_23">项目上线</span>
          </div>
          <div class="block_11 flex-row justify-between">
            <img class="thumbnail_4" referrerpolicy="no-referrer"
              src="./assets/img/psxqckeggopgb0ixjp5vaf9w6xlexgh7mzznd5c6b2a0-1ea4-4b71-bf6c-2a8d246093af.png" />
            <img class="thumbnail_4" referrerpolicy="no-referrer"
              src="./assets/img/psxqckeggopgb0ixjp5vaf9w6xlexgh7mzznd5c6b2a0-1ea4-4b71-bf6c-2a8d246093af.png" />
          </div>
          <div class="block_12 flex-row">
            <img class="image_9" referrerpolicy="no-referrer"
              src="./assets/img/psdmo7ihehlcsdm0w5tzccaw27zsx5mc0gz2ca96fe0-0399-4510-a490-be5ec0b25169.png" />
            <div class="block_6 flex-col">
              <div class="box_20 flex-row">
                <div class="text-wrapper_4 f-hove flex-col">
                  <el-popover placement="top-start" width="380" trigger="hover" title="原型设计"
                    content="以领域建模为基础，领域模型深刻反映业务的本质，为产品价值的实现提供了根本保障。">
                    <span class="text_24" slot="reference">
                      <span style="height: 45px; line-height: 45px; width: 190px; display: block; padding-left: 10px;">
                        原型设计
                      </span>
                      <img class="f-wu1" src="./assets/wh.png" alt="">
                      <img class="f-wu2" src="./assets/wh2.png" alt="">
                    </span>
                  </el-popover>
                </div>
              </div>
              <div class="box_19 flex-row" style="position: relative;">
                <img class="f-jt" src="./assets/jt.png" alt="">
                <div class="text-wrapper_3 f-hove flex-col">
                  <el-popover placement="top-start" width="450" title="领域建模" trigger="hover"
                    content="有效应对复杂性和多变性挑战。传统开发方式无此环节，项目成本随业务复杂度几何级上升，存在较大烂尾风险，需求变动或扩展时修改成本较高。">
                    <span class="text_24" slot="reference">
                      <span
                        style="height: 45px; line-height: 45px; width: 240px; display: block; padding-left: 10px;">领域建模</span>
                      <img class="f-wu1" src="./assets/wh.png" alt="">
                      <img class="f-wu2" src="./assets/wh2.png" alt="">
                    </span>
                  </el-popover>
                </div>
              </div>
              <div class="box_22 flex-row">
                <div class="text-wrapper_6 f-hove flex-col">
                  <el-popover placement="top-start" width="400" trigger="hover" title="应用层开发"
                    content="由于领域模型封装了深层业务知识，应用层是很薄的一层，代码很简单，而且各个功能点的代码是离散的，修改灵活且成本较低。">
                    <span class="text_24" slot="reference">
                      <span
                        style="height: 45px; line-height: 45px; width: 240px; display: block; padding-left: 10px;">应用层开发</span>
                      <img class="f-wu1" src="./assets/wh.png" alt="">
                      <img class="f-wu2" src="./assets/wh2.png" alt="">
                    </span>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="text-wrapper_7 flex-col f-hove">
              <el-popover placement="top-start" width="350" trigger="hover" title="测试/更正"
                content="不存在深层bug，工期较短，且容易预计。">
                <span class="text_24" slot="reference">
                  <span
                    style="height: 45px; line-height: 45px; width: 110px; display: block; padding-left: 10px;">测试/更正</span>
                  <img class="f-wu1" src="./assets/wh.png" alt="">
                  <img class="f-wu2" src="./assets/wh2.png" alt="">
                </span>
              </el-popover>
            </div>
            <img class="image_10" referrerpolicy="no-referrer"
              src="./assets/img/psb3gifxli6berjvi44rq7kroewue12xfifccf6afd-a569-4ad1-9ddc-2f95ed227e03.png" />
            <div class="text-wrapper_8 flex-col">
              <span class="text_24" slot="reference">
                <span
                  style="height: 45px; line-height: 45px; width: 70px; display: block; padding-left: 10px;">需求调查</span>
              </span>
            </div>
          </div>
          <div class="block_13 flex-row justify-between">
            <img class="thumbnail_5" referrerpolicy="no-referrer"
              src="./assets/img/ps4riwm0gzbq7qi70q55p7sxsnm1wgakkh284cac05-5048-4012-85a9-df98eacb2c0c.png" />
            <img class="thumbnail_5" referrerpolicy="no-referrer"
              src="./assets/img/ps4riwm0gzbq7qi70q55p7sxsnm1wgakkh284cac05-5048-4012-85a9-df98eacb2c0c.png" />
          </div>
        </div>
        <div class="group_5 flex-col">
          <div class="section_9 flex-row">
            <div class="image-text_4 flex-row justify-between">
              <img class="thumbnail_3" referrerpolicy="no-referrer"
                src="./assets/img/ps5f6im6wemf27guhkbk7rq4b5rb3zfeq06542192a9-9cee-4b01-b12c-92245a2eb915.png" />
              <span class="text-group_3">传统开发流程</span>
            </div>
          </div>
          <div class="text-wrapper_17 flex-row justify-between">
            <span class="text_30">项目启动</span>
            <span class="text_31">项目上线</span>
          </div>
          <div class="section_10 flex-row justify-between">
            <img class="thumbnail_4" referrerpolicy="no-referrer"
              src="./assets/img/psxqckeggopgb0ixjp5vaf9w6xlexgh7mzznd5c6b2a0-1ea4-4b71-bf6c-2a8d246093af.png" />
            <img class="thumbnail_4" referrerpolicy="no-referrer"
              src="./assets/img/psxqckeggopgb0ixjp5vaf9w6xlexgh7mzznd5c6b2a0-1ea4-4b71-bf6c-2a8d246093af.png" />
          </div>
          <div class="section_11 flex-row">
            <img class="image_11" referrerpolicy="no-referrer"
              src="./assets/img/psz0i8ly6jh4q6oeyg2975d9nzfk18x6jtf311b157-1ddf-40cd-9acc-be342ee34919.png" />
            <div class="block_9 flex-col">
              <div class="section_12 flex-row">
                <div class="text-wrapper_10 flex-col f-hove">
                  <el-popover placement="top-start" width="380" trigger="hover" title="原型设计"
                    content="产品经理和开发团队经常发生矛盾，产品经理觉得很简单的功能开发团队认为实现不了，原因在于模型不一致。">
                    <span class="text_24" slot="reference">
                      <span
                        style="height: 45px; line-height: 45px; width: 160px; display: block; padding-left: 10px;">原型设计</span>
                      <img class="f-wu1" src="./assets/wh.png" alt="">
                      <img class="f-wu2" src="./assets/wh2.png" alt="">
                    </span>
                  </el-popover>
                </div>
              </div>
              <div class="section_14 flex-row">
                <div class="text-wrapper_12 flex-col">
                  <span class="text_24" slot="reference">
                    <span
                      style="height: 45px; line-height: 45px; width: 340px; display: block; padding-left: 10px;">开发</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="block_10 flex-col f-hove">
              <el-popover placement="top-start" width="370" trigger="hover" title="测试/更正"
                content="很多深层业务逻辑冲突要到测试阶段才能发现，已扩散到整个系统，就像癌细胞扩散到全身，解决起来非常复杂，甚至无法解决。">
                <span class="text_24" slot="reference">
                  <span
                    style="height: 45px; line-height: 45px; width: 300px; display: block; padding-left: 10px;">测试/更正</span>
                  <img class="f-wu1" src="./assets/wh.png" alt="">
                  <img class="f-wu2" src="./assets/wh2.png" alt="">
                </span>
              </el-popover>
              <img class="image_12" referrerpolicy="no-referrer"
                src="./assets/img/psqotp5mnnfxcv8fxtitmrgtmp5tygicwk8d149300-76e2-4859-b6f9-60f4499d41cd.png" />
            </div>

            <div class="text-wrapper_13 flex-col">
              <span class="text_24" slot="reference">
                <span
                  style="height: 45px; line-height: 45px; width: 70px; display: block; padding-left: 10px;">需求调查</span>
              </span>
            </div>
          </div>
          <div class="section_15 flex-row justify-between">
            <img class="thumbnail_5" referrerpolicy="no-referrer"
              src="./assets/img/ps4riwm0gzbq7qi70q55p7sxsnm1wgakkh284cac05-5048-4012-85a9-df98eacb2c0c.png" />
            <img class="thumbnail_5" referrerpolicy="no-referrer"
              src="./assets/img/ps4riwm0gzbq7qi70q55p7sxsnm1wgakkh284cac05-5048-4012-85a9-df98eacb2c0c.png" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="概念建模概论" :visible.sync="dialogVisible" :before-close="handleClose" width="1000px">
      <div class="prism-player" id="J_prismPlayer"></div>
    </el-dialog>

    <el-dialog title="技术建模概述" :visible.sync="dialogVisible2" top="10vh" :before-close="handleClose" width="1280px">
      <div class="f-prism-player">
        <div class="prism-player" id="J_prismPlayer2"></div>
        <div class="f-ul">
          <div class="f-tit">选集</div>
          <div class="f-li" :class="{'f-state': index == 1}" @click="watchBtn2(1)">
            <span>1. 构建关联导航机制</span>
          </div>
          <div class="f-li" :class="{'f-state': index == 2}" @click="watchBtn2(2)">
            <span>2.  构建动作相应机制</span>
          </div>
          <div class="f-li" :class="{'f-state': index == 3}" @click="watchBtn2(3)">
            <span>3.  构建信息获取接口</span>
          </div>
          <div class="f-li" :class="{'f-state': index == 4}" @click="watchBtn2(4)">
            <span>4.  封装“系统”行为</span>
          </div>
          <div class="f-li" :class="{'f-state': index == 5}" @click="watchBtn2(5)">
            <span>5.  适配持久化框架</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      player: null,
      title: '',
      source: '',
      source2: '',
      index: 1,
      list: [
        'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/5a7c668090d571ee804c4531948c0102/dce4c7cf0b350b6c3ccf8f91ddcf5268-sd.mp4',
        'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/bb2c4770917071ee80245017f0f80102/7f66fbb3ee539acc830fdd29e4d335a0-sd.mp4',
        'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/088b143090ca71eebdd06732b68e0102/53e002458e903c4aab49a01db1c3a7fe-sd.mp4',
        'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/982aa130911771ee978c4531958c0102/0c2d16cded3f77c575c902611634f6ac-sd.mp4',
        'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/0864043090ca71eeaac56732b68f0102/635dd3b5cfde6ffe380ad313529784c6-sd.mp4'
      ]
    };
  },
  methods: {
    watchBtn2(id) {
      if (this.player) {
        this.player.dispose();
        this.player = null;
      } else this.dialogVisible2 = true;

      this.index = id;
      let _this = this;
      setTimeout(() => {
        _this.player = new Aliplayer({
          id: "J_prismPlayer2",
          autoplay: true,
          width: "960px",
          height: "540px",
          source: _this.list[id - 1]
        });
      }, 120);
    },
    watchBtn() {
      let _this = this;
      this.dialogVisible = true;
      setTimeout(() => {
        _this.player = new Aliplayer({
          id: "J_prismPlayer",
          autoplay: true,
          width: "960px",
          height: "540px",
          source: 'https://obaselotus.oss-cn-shanghai.aliyuncs.com/vod-6cc464/08a8ae5090ca71ee80235017f0f80102/30de33f216486da0ee75f9faa4d842ab-sd.mp4'
        });
      }, 120);
    },
    handleClose() {
      if (this.player) {
        this.player.dispose();
        this.player = null;
      }
      this.dialogVisible = false;
      this.dialogVisible2 = false;
    },
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.prism-player {
  width: 960px;
  height: 540px;
}

.f-prism-player {
  display: flex;
  font-size: 20px;
}

.f-ul .f-tit {
  padding: 0 0 20px 20px;
  font-size: 20px;
}

.f-ul {
  padding-left: 20px;
}

.f-ul .f-li {
  width: 260px;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
  color: #666;
}

.f-ul .f-li span {
  padding-left: 20px;
}

.f-ul .f-state {
  background: #FAEAE1;
  color: #EC6817;
}

::v-deep .el-dialog__header {
  padding-bottom: 0;
}

::v-deep .el-dialog {
  border-radius: 10px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 28px;
  color: #333;
}

::v-deep .el-dialog__title {
  font-size: 22px;
  font-weight: bold;
}
</style>