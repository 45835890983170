<template>
  <div id="app">
    <heads />
    <router-view />

    <div class="f-section_4 flex-row" @click="scrollToTop" v-show="scrollTop > 500">
      <div class="image-text_4 flex-col justify-between">
        <img class="label_9" referrerpolicy="no-referrer" src="./assets/ding.png" />
        <span class="text-group_1">回顶部</span>
      </div>
    </div>
  </div>
</template>
<script>
import heads from './assets/head.vue'
export default {
  name: 'App',
  data() {
    return {
      scrollTop: 0
    }
  },
  components: {
    heads
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    updateScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }
  },
}
</script>
<style lang="css">
@import "./assets/common.css";

.f-w1200 {
  width: 1200px;
  margin: 0 auto;
}

.f-section_4 {
  background-color: rgba(242, 242, 242, 1);
  border-radius: 4px;
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 99;
  bottom: 100px;
  cursor: pointer;
  right: 100px;
  border: 1px solid rgba(230, 230, 230, 1);
  margin-top: 176px;
}

.f-section_4 .image-text_4 {
  width: 41px;
  height: 45px;
  margin: 20px 0 0 19px;
}

.f-section_4 .label_9 {
  width: 24px;
  height: 24px;
  margin-left: 9px;
}

.f-section_4 .text-group_1 {
  width: 41px;
  height: 13px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  font-family: MiSans-Regular;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin-top: 8px;
}

.f-box {
  display: flex;
  justify-content: space-between;
}

.f-box .f-title {
  font-family: MiSans;
  font-weight: 600;
  font-size: 24px;
  color: #333;
  line-height: 32px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.f-box .f-text-box {
  padding-top: 40px;
}

.f-box .f-text-ul {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.f-box .f-text-ul .f-texts {
  font-size: 16px;
  color: #333;
}

.f-box .f-code {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.f-box .f-code .f-ewm {
  width: 100px;
  height: 100px;
}

.f-box .f-dh {
  display: flex;
  align-items: center;
}

.f-box .f-dh span {
  font-family: MiSans;
  font-weight: 600;
  font-size: 20px;
  color: #ED5118;
}

.f-box .f-right-text {
  padding-left: 20px;
}

.f-box .f-kf {
    width: 460px;
    height: 280px;
}

.f-box .f-gb {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -22px;
  cursor: pointer;
}

.f-box .f-tes {
  font-family: MiSans;
  font-size: 13px;
  padding-top: 5px;
  color: #999999;
}

.f-box .f-text-data {
  padding-top: 30px;
  font-family: MiSans;
  font-weight: 400;
  font-size: 16px;
  color: #333;
}
.el-popover{
  background: #FFFCFA !important;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.15) !important;
  border-radius: 10px !important;
  border: 1px solid #CCB4A3 !important;
  font-size: 16px !important;
  color: #666 !important;
  line-height: 32px !important;
  top: -10px;
}

.el-popover__title {
  font-weight: 600;
}
</style>
