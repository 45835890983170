<template>
  <div class="page flex-col">
    <div class="box_38 flex-col">
      <span class="text_65">案例索引</span>
      <div class="box_39 flex-col"></div>
      <div class="box_40" :class="{ 'f-state': state == 'section1' }" @click="scrollToAnchor('section1')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section1'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">银行反假货币监管培训</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section2' }" @click="scrollToAnchor('section2')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section2'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">格林凯尔APP</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section3' }" @click="scrollToAnchor('section3')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section3'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">实验教学管理系统</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section4' }" @click="scrollToAnchor('section4')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section4'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">残联大学生就业创业</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section5' }" @click="scrollToAnchor('section5')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section5'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">餐事行</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section6' }" @click="scrollToAnchor('section6')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section6'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">律师事务所业务管理平台</span>
      </div>
      <div class="box_40" :class="{ 'f-state': state == 'section7' }" @click="scrollToAnchor('section7')">
        <img class="thumbnail_2" referrerpolicy="no-referrer" v-if="state == 'section7'"
          src="./assets/img/pspr9i95dixo73y0pbznet3fl60vpesou1d269ca6-bcd5-407d-8cf2-e5ca4b3e1697.png" />
        <div class="thumbnail_2" v-else></div>
        <span class="text_66">更多案例</span>
      </div>
    </div>
    <div class="f-tis">
      <div class="f-text">领域建模本身不特定于行业。
        您无需寻找对口案例，这些案例仅为说明我们创立的领域建模技术已经非常成熟。</div>
    </div>
    <div class="block_1 flex-row">
      <div class="section_5 flex-col">
        <div class="box_58"></div>
        <div class="f-w800 flex-col">
          <div class="text-wrapper_67 " id="section1" ref="sectionRef1">
            <div class="flex-row justify-between">
              <span class="text_14">案例一</span>
              <span class="text_15">银行反假货币监管培训</span>
            </div>
            <div class="box_5 flex-col"></div>
          </div>
          <span class="text_16">案例客户：</span>
          <span class="text_17">银行反假货币监管培训系统</span>
          <span class="text_18">案例介绍：</span>
          <div class="text-group_6 flex-col justify-between">
            <span class="text_19">
              该系统用于人民银行对商业银行的监管和培训，以及人民银行或者商业银行本机构的培训和学习。可以向自己的下辖商行或者下级机构发布任务，可以发布的任务包括：理论考试、实操考试、学习任务、培训计划上报任务、考证任务、信息上报任务，需要对机构的学员的任务完成情况进行监督。人民银行可以对自己需要完成的任务进行管理。
            </span>
            <span class="text_20">领域模型-场景示例：</span>
          </div>
          <div class="box_61 flex-row justify-between">
            <el-image class="image_2" :preview-src-list="[modelImage1]"
              :src="modelImage1">
            </el-image>
          </div>
          <span class="text_21">项目效益：</span>
          <img class="image_3" referrerpolicy="no-referrer"
            src="./assets/img/psu7vcktuzjunfxqv9zj9vhi40cxilcf9wu24ace62a-b4bf-4aca-851d-3f52ffc068b6.png" />
          <span class="text_22">系统成果展示：</span>
          <div class="box_62 flex-row justify-between">
            <el-image class="group_4" fit="cover" :preview-src-list="image1"
            :src="image1[0]" />
            <el-image class="group_5" fit="cover" :preview-src-list="image1"
            :src="image1[1]" />
            <el-image class="group_6" fit="cover" :preview-src-list="image1"
            :src="image1[2]" />
          </div>
          <div class="box_63 flex-row justify-between">
            <el-image class="box_10" fit="cover" :preview-src-list="image1"
            :src="image1[3]" />
            <el-image class="box_11" fit="cover" :preview-src-list="image1"
            :src="image1[4]" />
            <el-image class="box_12" fit="cover" :preview-src-list="image1"
            :src="image1[5]" />
          </div>
          <div class="text-wrapper_67" id="section2" ref="sectionRef2">
            <div class="flex-row justify-between">
              <span class="text_14">案例二</span>
              <span class="text_15">格林凯尔APP</span>
            </div>
            <div class="box_5 flex-col" style="width: 230px;"></div>
          </div>
          <span class="text_25">案例客户：</span>
          <span class="text_26">湖北格林凯尔农业科技有限公司</span>
          <span class="text_27">项目效益：</span>
          <img class="image_4" referrerpolicy="no-referrer"
            src="./assets/img/psal3di1juz4ak8kkoag39u7a8ci2jtyiw226cf835-e2d8-466a-8f0c-2f8d5d116ce1.png" />
          <span class="text_28">客户评价：</span>
          <span class="text_29">
            你们这个领域建模的技术真应该在软件行业推广开来。之前花了几十万给上一个公司做了半年，拿到手各种问题。抱着死马当活马医选择了obase领域建模开发方法，高质量交付已经是惊喜了，没想到还节省了这么多的费用。
          </span>
          <span class="text_30">案例背景：</span>
          <span class="text_31">该项目整体开发均由我公司进行。以下仅展示该项目中领域建模环节的实施。</span>
          <span class="text_32">项目介绍：</span>
          <div class="text-group_7 flex-col justify-between">
            <span class="paragraph_2">
              “格林凯尔APP”项目的主体是一个自营性电商平台，经营的产品是格林凯尔公司生产的特色化肥，向农户销售。与普通电商平台相比最大的差别是这些化肥是根据不同地区（具体到县）的土壤、气候、特色作物和作物生长阶段定制的。
              <br />
              格林凯尔公司将在各地建立服务站，服务站的主要功能是为下单的农户提供配送服务（农户自提或者送货上门）。服务站的库存由公司调拨，本项目需要为服务站的库存调拨和库存管理提供信息化支持。
              <br />
              在平台运营过程中，会开展线上线下结合的促销活动，如推荐客户返现、团购、抽奖等。线下部分通常会与当地服务站联合开展。本项目需要对何种形式的促销活动提供信息化支持，未来还需要提供数据分析支持。
            </span>
            <span class="text_33">领域模型-场景示例：</span>
          </div>
          <el-image class="image_5" :preview-src-list="[modelImage2]"
              :src="modelImage2" />
          <span class="text_34">系统成果展示：</span>
          <div class="box_64 flex-row justify-between">
            <el-image class="block_5" fit="cover" :preview-src-list="image2"
            :src="image2[0]" />
            <el-image class="block_6" fit="cover" :preview-src-list="image2"
            :src="image2[1]" />
            <el-image class="block_7" fit="cover" :preview-src-list="image2"
            :src="image2[2]" />
          </div>
          <div class="box_65 flex-row justify-between">
            <el-image class="block_8" fit="cover" :preview-src-list="image2"
            :src="image2[3]" />
            <el-image class="block_9" fit="cover" :preview-src-list="image2"
            :src="image2[4]" />
            <el-image class="block_10" fit="cover" :preview-src-list="image2"
            :src="image2[5]" />
          </div>
          <div class="text-wrapper_67 " id="section3" ref="sectionRef3">
            <div class="flex-row justify-between">
              <span class="text_14">案例三</span>
              <span class="text_15">湖北汽车工业学院物理实验教学管理系统</span>
            </div>
            <div class="box_5 flex-col" style="width: 570px;"></div>
          </div>
          <span class="text_37">案例客户：</span>
          <span class="text_38">湖北汽车工业学院</span>
          <span class="text_39">案例介绍：</span>
          <div class="text-group_8 flex-col justify-between">
            <span class="paragraph_3">
              该系统是为方便学院物理实验教学管理。学院老师线上进行课程安排，成绩管理，学生可在系统内查询成绩，预约课程。该系统由我们进行领域建模环节，领域建模基于obase框架支撑，系统开发过程中及完成后：
              <br />
              1.业务理解全面、准确、有深度且能贯通至所有团队成员，确保不会因为深层逻辑冲突导致部分功能无法实现。
              <br />
              2.功能扩展，后期学院在系统使用过程中，想要增加新的功能，无需对系统进行重构，只需要增加新的功能模块即可。
              <br />
              3.性能扩展，后期学院人员增加或合并几个学院使用该系统，无需对系统进行重构，只需要增加服务器，就可满足用户量和数据增长的需求。
              <br />
              4.并发支持，应对大流量冲击时支持分布式部署，确保各节点不发生冲突。
            </span>
            <span class="text_40">领域模型-场景示例：</span>
          </div>
          <el-image class="image_6" :preview-src-list="[modelImage3]"
              :src="modelImage3" />
          <span class="text_41">系统成果展示：</span>
          <div class="box_66 flex-row justify-between">
            <el-image class="section_1" fit="cover" :preview-src-list="image3"
            :src="image3[0]" />
            <el-image class="section_2" fit="cover" :preview-src-list="image3"
            :src="image3[1]" />
            <el-image class="section_3" fit="cover" :preview-src-list="image3"
            :src="image3[2]" />
          </div>
          <div class="text-wrapper_67 " id="section4" ref="sectionRef4">
            <div class="flex-row justify-between">
              <span class="text_14">案例四</span>
              <span class="text_15">残联大学生就业创业</span>
            </div>
            <div class="box_5 flex-col" style="width: 320px;"></div>
          </div>
          <span class="text_44">案例客户：</span>
          <span class="text_45">省残联单位</span>
          <span class="text_46">案例介绍：</span>
          <div class="text-group_9 flex-col justify-between">
            <span class="paragraph_4">
              此系统主要是提供给湖北省残联的各下属地区残联单位提交残疾人创业就业相关的信息，省残联来进行审核，并最终完成补助的发放。需要提交与审核的信息包括：
              <br />
              1.残疾大学生补助申请&nbsp;
              <br />
              2.残疾大学生信息申报
              <br />
              3.就业创业示范基地申报
              <br />
              4.自主创业残疾人信息申报
              <br />
              5.技术能手信息申报
            </span>
            <span class="text_47">领域模型-场景示例：</span>
          </div>
          <el-image class="image_7" :preview-src-list="[modelImage4]"
              :src="modelImage4" />
          <span class="text_48">系统成果展示：</span>
          <div class="box_67 flex-row justify-between">
            <el-image class="box_20" fit="cover" :preview-src-list="image4"
            :src="image4[0]" />
            <el-image class="box_21" fit="cover" :preview-src-list="image4"
            :src="image4[1]" />
            <el-image class="box_22" fit="cover" :preview-src-list="image4"
            :src="image4[2]" />
          </div>
          <div class="text-wrapper_67 " id="section5" ref="sectionRef5">
            <div class="flex-row justify-between">
              <span class="text_14">案例五</span>
              <span class="text_15">餐事行</span>
            </div>
            <div class="box_5 flex-col" style="width: 150px;"></div>
          </div>
          <span class="text_51">案例客户：</span>
          <span class="text_52">个人</span>
          <span class="text_53">项目介绍：</span>
          <span class="text_54">
            餐事行是一个餐饮saas平台，向餐饮企业免费开发使用（一期功能全部免费，后续版本可能上线收费功能）。餐饮店老板店长或者厨师长等使用该系统进行菜品管理、盈亏分析、店铺运营辅助等功能。
          </span>
          <span class="text_55">领域模型-场景示例：</span>
          <el-image class="image_8" :preview-src-list="[modelImage5]"
              :src="modelImage5" />
          <span class="text_56">系统成果展示：</span>
          <div class="box_68 flex-row justify-between">
            <el-image class="group_7" fit="cover" :preview-src-list="image5"
            :src="image5[0]" />
            <el-image class="group_8" fit="cover" :preview-src-list="image5"
            :src="image5[1]" />
            <el-image class="group_9" fit="cover" :preview-src-list="image5"
            :src="image5[2]" />
          </div>
          <div class="box_69 flex-row justify-between">
            <el-image class="box_26" fit="cover" :preview-src-list="image5"
            :src="image5[3]" />
            <el-image class="box_27" fit="cover" :preview-src-list="image5"
            :src="image5[4]" />
            <el-image class="box_28" fit="cover" :preview-src-list="image5"
            :src="image5[5]" />
          </div>
          <div class="text-wrapper_67 " id="section6" ref="sectionRef6">
            <div class="flex-row justify-between">
              <span class="text_14">案例六</span>
              <span class="text_15">律师事务所业务管理平台</span>
            </div>
            <div class="box_5 flex-col" style="width: 380px;"></div>
          </div>
          <span class="text_59">案例客户：</span>
          <span class="text_60">律师事务所</span>
          <span class="text_61">项目介绍：</span>
          <span class="text_62">
            该律所业务管理平台是一个saas平台，律师通过平台与委托人保持联系，委托人可随时关注案情进展。律所通过该平台对律师及案件进行综合管理，提高律所运转效率，降低律所运营成本。
          </span>
          <span class="text_63">领域模型-场景示例：</span>
          <el-image class="image_9" :preview-src-list="[modelImage6]"
              :src="modelImage6" />
          <span class="text_64">系统成果展示：</span>
          <div class="box_70 flex-row justify-between">
            <el-image class="box_31" fit="cover" :preview-src-list="image6"
            :src="image6[0]" />
            <el-image class="box_32" fit="cover" :preview-src-list="image6"
            :src="image6[1]" />
            <el-image class="box_33" fit="cover" :preview-src-list="image6"
            :src="image6[2]" />
          </div>
          <div class="box_71 flex-row justify-between">
            <el-image class="box_35" fit="cover" :preview-src-list="image6"
            :src="image6[3]" />
            <el-image class="box_36" fit="cover" :preview-src-list="image6"
            :src="image6[4]" />
            <el-image class="box_37" fit="cover" :preview-src-list="image6"
            :src="image6[5]" />
          </div>
        </div>
        <div class="group_10" id="section7" ref="sectionRef7">
          <div class="f-w1200 flex-col ">
            <div class="text-wrapper_73 flex-row"><span class="text_73">更多案例</span></div>
            <div class="text-wrapper_74 flex-row">
              <span class="paragraph_5">
                这些案例仅为说明我们创立的领域建模技术已经非常成熟
                <br />
                领域建模本身不特定于行业，您无需寻找对口案例
              </span>
            </div>
            <div class="box_72 flex-row justify-between">
              <div class="box_41 flex-col">
                <span class="text_74">外链云存储平台——随意云</span>
                <span class="text_75">为网站主提供文件存储与直链服务，存储容量达PB级</span>
                <div class="group_22 flex-row justify-between">
                  <div class="text-wrapper_18 flex-col"><span class="text_76">文件存储</span></div>
                  <div class="text-wrapper_19 flex-col"><span class="text_77">外链</span></div>
                  <div class="text-wrapper_20 flex-col"><span class="text_78">线上支付</span></div>
                </div>
                <div class="group_23 flex-row justify-between">
                  <div class="text-wrapper_21 flex-col"><span class="text_79">图片搬家</span></div>
                  <div class="text-wrapper_22 flex-col"><span class="text_80">高并发场景</span></div>
                </div>
                <span class="text_81">武汉随意云科技有限公司</span>
              </div>
              <div class="box_42 flex-col">
                <span class="text_82">中职物联智慧安防</span>
                <span class="paragraph_6">
                  采用专业传感器收集电器、消防设施等设备的实时数据；
                  <br />
                  同时建立多维数据模型，从多个维度评估安防态势
                </span>
                <div class="section_6 flex-row justify-between">
                  <div class="text-wrapper_23 flex-col"><span class="text_83">大数据</span></div>
                  <div class="text-wrapper_24 flex-col"><span class="text_84">电力线路监测</span></div>
                  <div class="text-wrapper_25 flex-col"><span class="text_85">数据分析</span></div>
                </div>
                <span class="text_86">中职物联（湖北）信息科技有限公司</span>
              </div>
              <div class="box_43 flex-col">
                <span class="text_87">宴会云</span>
                <span class="text_88">属于SAAS平台，无需本地化部署，包含多个组件</span>
                <div class="grid_5 flex-row">
                  <div class="text-wrapper_26 flex-col"><span class="text_89">客户跟进</span></div>
                  <div class="text-wrapper_27 flex-col"><span class="text_90">档期管理</span></div>
                  <div class="text-wrapper_28 flex-col"><span class="text_91">官网自动生成</span></div>
                  <div class="text-wrapper_29 flex-col"><span class="text_92">小程序自动生成</span></div>
                </div>
                <span class="text_93">创业团队</span>
              </div>
            </div>
            <div class="box_73 flex-row justify-between">
              <div class="box_44 flex-col">
                <span class="text_94">科艾乐实验室检测管理系统</span>
                <span class="text_95">应用于土壤检测全流程管理</span>
                <div class="grid_6 flex-row">
                  <div class="text-wrapper_30 flex-col"><span class="text_96">仪器交互</span></div>
                  <div class="text-wrapper_31 flex-col"><span class="text_97">报告生成</span></div>
                  <div class="text-wrapper_32 flex-col"><span class="text_98">在线下单</span></div>
                  <div class="text-wrapper_33 flex-col"><span class="text_99">检验流程管理</span></div>
                </div>
                <span class="text_100">湖北科艾乐检测科技有限公司</span>
              </div>
              <div class="box_45 flex-col">
                <span class="text_101">格林凯尔订单全流程管理系统</span>
                <span class="paragraph_7">
                  核心节点包括订单分派、生产进度跟踪、实时进度
                  <br />
                  通知、物流跟踪等
                </span>
                <div class="section_7 flex-row justify-between">
                  <div class="text-wrapper_34 flex-col"><span class="text_102">生产管理</span></div>
                  <div class="text-wrapper_35 flex-col"><span class="text_103">库存管理</span></div>
                  <div class="text-wrapper_36 flex-col"><span class="text_104">线上交易</span></div>
                </div>
                <span class="text_105">湖北格林凯尔农业科技有限公司</span>
              </div>
              <div class="box_46 flex-col">
                <span class="text_106">瞰见-现场首发</span>
                <span class="paragraph_8">
                  适用于婚礼等活动现场的互动娱乐平台，同时可供婚庆
                  <br />
                  供应商入驻，打造婚庆领域的垂直电商平台
                </span>
                <div class="section_8 flex-row justify-between">
                  <div class="text-wrapper_37 flex-col"><span class="text_107">现场互动娱乐</span></div>
                  <div class="text-wrapper_38 flex-col"><span class="text_108">电子邀请函</span></div>
                  <div class="text-wrapper_39 flex-col"><span class="text_109">客户关系管理</span></div>
                </div>
                <span class="text_110">瞰见武汉科技有限公司</span>
              </div>
            </div>
            <div class="box_74 flex-row justify-between">
              <div class="box_48 flex-col">
                <span class="text_111">小秘书</span>
                <span class="text_112">个人数字助理</span>
                <div class="grid_7 flex-row">
                  <div class="text-wrapper_40 flex-col"><span class="text_113">日程管理</span></div>
                  <div class="text-wrapper_41 flex-col"><span class="text_114">课程表</span></div>
                  <div class="text-wrapper_42 flex-col"><span class="text_115">多端同步</span></div>
                  <div class="text-wrapper_43 flex-col"><span class="text_116">数据加密</span></div>
                </div>
                <span class="text_117">武汉微搜信息科技有限公司</span>
              </div>
              <div class="box_49 flex-col">
                <span class="text_118">比格票据网</span>
                <span class="text_119">票据保证业务中介平台</span>
                <div class="grid_8 flex-row">
                  <div class="text-wrapper_44 flex-col"><span class="text_120">担保交易</span></div>
                  <div class="text-wrapper_45 flex-col"><span class="text_121">交易撮合</span></div>
                  <div class="text-wrapper_46 flex-col"><span class="text_122">虚拟货币</span></div>
                  <div class="text-wrapper_47 flex-col"><span class="text_123">线上支付</span></div>
                </div>
                <span class="text_124">武汉大鄂票据服务有限公司</span>
              </div>
              <div class="box_50 flex-col">
                <span class="text_125">教育云盘</span>
                <span class="text_126">供学校使用的专业网盘</span>
                <div class="box_75 flex-row justify-between">
                  <div class="text-wrapper_48 flex-col"><span class="text_127">网盘</span></div>
                  <div class="text-wrapper_49 flex-col"><span class="text_128">机构管理</span></div>
                </div>
                <span class="text_129">创业团队</span>
              </div>
            </div>
            <div class="box_76 flex-row justify-between">
              <div class="box_52 flex-col">
                <span class="text_130">易筑云收验货系统</span>
                <span class="text_131">智慧工地材料自动称重、验货系统</span>
                <div class="box_77 flex-row justify-between">
                  <div class="text-wrapper_50 flex-col"><span class="text_132">自动称重</span></div>
                  <div class="text-wrapper_51 flex-col"><span class="text_133">设备控制</span></div>
                  <div class="text-wrapper_52 flex-col"><span class="text_134">验货管理</span></div>
                </div>
                <span class="text_135">江苏易筑科技有限公司</span>
              </div>
              <div class="box_53 flex-col">
                <span class="text_136">健教云宣</span>
                <span class="text_137">供医院使用的健康科普SaaS</span>
                <div class="box_78 flex-row justify-between">
                  <div class="text-wrapper_53 flex-col"><span class="text_138">机构管理</span></div>
                  <div class="text-wrapper_54 flex-col"><span class="text_139">内容发布</span></div>
                  <div class="text-wrapper_55 flex-col"><span class="text_140">线上问诊</span></div>
                </div>
                <span class="text_141">武汉云科智博信息技术有限公司</span>
              </div>
              <div class="box_55 flex-col">
                <span class="text_142">科研创新平台</span>
                <span class="text_143">TRIZ理论应用工具</span>
                <div class="block_15 flex-row justify-between">
                  <div class="text-wrapper_56 flex-col"><span class="text_144">物场模型</span></div>
                  <div class="text-wrapper_57 flex-col"><span class="text_145">知识库</span></div>
                </div>
                <span class="text_146">某军事院校</span>
              </div>
            </div>
            <div class="box_79 flex-row justify-between">
              <div class="block_11 flex-col">
                <span class="text_147">采购宝</span>
                <span class="text_148">按单采购、打单、发货助手</span>
                <div class="box_80 flex-row justify-between">
                  <div class="text-wrapper_58 flex-col"><span class="text_149">订单同步</span></div>
                  <div class="text-wrapper_59 flex-col"><span class="text_150">按单采购</span></div>
                  <div class="text-wrapper_60 flex-col"><span class="text_151">分销代发</span></div>
                </div>
                <span class="text_152">上海旺铺信息技术有限公司</span>
              </div>
              <div class="block_12 flex-col">
                <span class="text_153">分销平台——591</span>
                <span class="text_154">货源展示及分销代发平台</span>
                <div class="box_81 flex-row justify-between">
                  <div class="text-wrapper_61 flex-col"><span class="text_155">货源搜索</span></div>
                  <div class="text-wrapper_62 flex-col"><span class="text_156">广告位竞价</span></div>
                  <div class="text-wrapper_63 flex-col"><span class="text_157">一键上货</span></div>
                </div>
                <span class="text_158">上海旺铺信息技术有限公司</span>
              </div>
              <div class="block_13 flex-col">
                <span class="text_159">工地零星审批系统</span>
                <span class="text_160">工地临时用工用料申报审批系统</span>
                <div class="group_24 flex-row justify-between">
                  <div class="text-wrapper_64 flex-col"><span class="text_161">智慧工地</span></div>
                  <div class="text-wrapper_65 flex-col"><span class="text_162">工作流</span></div>
                  <div class="text-wrapper_66 flex-col"><span class="text_163">组织结构</span></div>
                </div>
                <span class="text_164">江苏易筑科技有限公司</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'section1',
      modelImage1: require('./assets/img/moxing1.png'),
      modelImage2: require('./assets/img/moxing2.png'),
      modelImage3: require('./assets/img/moxing3.png'),
      modelImage4: require('./assets/img/moxing4.png'),
      modelImage5: require('./assets/img/moxing5.png'),
      modelImage6: require('./assets/img/moxing6.png'),
      image1: [
        require('./assets/img/anli/anli1/1.png'),
        require('./assets/img/anli/anli1/2.png'),
        require('./assets/img/anli/anli1/3.png'),
        require('./assets/img/anli/anli1/4.png'),
        require('./assets/img/anli/anli1/5.png'),
        require('./assets/img/anli/anli1/6.png')
      ],
      image2: [
        require('./assets/img/anli/anli2/1.png'),
        require('./assets/img/anli/anli2/2.png'),
        require('./assets/img/anli/anli2/3.png'),
        require('./assets/img/anli/anli2/4.png'),
        require('./assets/img/anli/anli2/5.png'),
        require('./assets/img/anli/anli2/6.png')
      ],
      image3: [
        require('./assets/img/anli/anli3/1.png'),
        require('./assets/img/anli/anli3/2.png'),
        require('./assets/img/anli/anli3/3.png')
      ],
      image4: [
        require('./assets/img/anli/anli4/1.jpg'),
        require('./assets/img/anli/anli4/2.png'),
        require('./assets/img/anli/anli4/3.png')
      ],
      image5: [
        require('./assets/img/anli/anli5/1.png'),
        require('./assets/img/anli/anli5/2.png'),
        require('./assets/img/anli/anli5/3.png'),
        require('./assets/img/anli/anli5/4.png'),
        require('./assets/img/anli/anli5/5.png'),
        require('./assets/img/anli/anli5/6.png')
      ],
      image6: [
        require('./assets/img/anli/anli6/1.png'),
        require('./assets/img/anli/anli6/2.png'),
        require('./assets/img/anli/anli6/3.png'),
        require('./assets/img/anli/anli6/4.png'),
        require('./assets/img/anli/anli6/5.png'),
        require('./assets/img/anli/anli6/6.png')
      ]
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScrolls);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrolls);
  },
  methods: {
    updateScrolls() {
      let scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) + 200;
      this.handleScroll(scrollTop)
    },
    handleScroll(scrollTop) {
      const sectionRef1 = this.$refs.sectionRef1;
      const sectionRef2 = this.$refs.sectionRef2;
      const sectionRef3 = this.$refs.sectionRef3;
      const sectionRef4 = this.$refs.sectionRef4;
      const sectionRef5 = this.$refs.sectionRef5;
      const sectionRef6 = this.$refs.sectionRef6;
      const sectionRef7 = this.$refs.sectionRef7;

      if (sectionRef1 && scrollTop >= sectionRef1.offsetTop && scrollTop < sectionRef2.offsetTop) {
        this.state = 'section1';
      } else if (sectionRef2 && scrollTop >= sectionRef2.offsetTop && scrollTop < sectionRef3.offsetTop) {
        this.state = 'section2';
      } else if (sectionRef3 && scrollTop >= sectionRef3.offsetTop && scrollTop < sectionRef4.offsetTop) {
        this.state = 'section3';
      } else if (sectionRef4 && scrollTop >= sectionRef4.offsetTop && scrollTop < sectionRef5.offsetTop) {
        this.state = 'section4';
      } else if (sectionRef5 && scrollTop >= sectionRef5.offsetTop && scrollTop < sectionRef6.offsetTop) {
        this.state = 'section5';
      } else if (sectionRef6 && scrollTop >= sectionRef6.offsetTop && scrollTop < sectionRef7.offsetTop) {
        this.state = 'section6';
      } else if (sectionRef7 && scrollTop >= sectionRef7.offsetTop) {
        this.state = 'section7';
      }
    },
    goToAbout(url) {
      this.$router.push(url);
    },
    scrollToAnchor(anchor) {
      this.$nextTick(() => {
        const anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          const y = anchorElement.getBoundingClientRect().top + window.scrollY - 120; // 120px 的微调
          window.scrollTo({
            top: y,
            behavior: 'smooth'
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
@media (max-width: 1500px) {
  .f-w800 {
    overflow: hidden;
    margin: 0 0 0 400px !important;
  }

  .f-section_4 {
    right: 200px !important;
  }
}
</style>