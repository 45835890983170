<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="box_12 flex-col">
        <div class="box_5 flex-col">
          <div class="section_13 flex-col">
            <span class="text_9">
              “领域建模是软件开发流程中的一个环节，引入这一环节，可以在确保高品质的前提下降低开发成本30-50%”
            </span>
            <div class="group_1 flex-col"></div>
            <span class="text_10">陈&nbsp;&nbsp;瑞&nbsp;&nbsp;&nbsp;&nbsp;领域建模专家</span>
          </div>
        </div>
      </div>
      <div class="f-w1320 flex-col">
        <div class="block_3 flex-col">
          <div class="text-wrapper_25 flex-row"><span class="text_11">20年潜心研究，开创一套领域建模方法和技术</span></div>
          <div class="text-wrapper_26 flex-row">
            <span class="text_12">新技术，新工艺，大厂的品质，平常的价格</span>
          </div>
          <div class="f-tist">1. 领域建模的作用</div>
          <div class="image-wrapper_8 flex-row">
            <img class="image_3" referrerpolicy="no-referrer"
              src="./assets/img/ps4q03x7e7vvn8d5r9ycic1ce3ao6ta2olo447ee674-ec3c-44a7-9b6b-aa3dce045914.png" />
          </div>
          <div class="text-wrapper_27 flex-row">
            <span class="text_17">
              领域建模是软件开发流程的一个环节。传统开发流程并不存在这一环节（下图），我们历时二十年持续研发，创立了全新的理论体系、建模方法和技术框架，创造性地引入了这一环节（上图），实现了在确保高品质的前提下降低开发成本30-50%
            </span>
          </div>
          <div class="box_13 flex-row justify-between">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/psglprvahd6ifprdubskkf5jry9tefwolaa450159e8-ff4e-410f-92cc-ff00a4d33bf8.png" />
          </div>
          <div class="f-tist" style="padding-top: 30px;">2. 领域建模的内容</div>
          <div class="text-wrapper_28">
            <div class="paragraph_1">
              领域建模是对软件所涉及的业务领域进行深入分析，抽象出领域知识（业务逻辑、业务规则、规律、规范等），并将其封装成稳定的程序模块（即领域模型）。是软件开发的核心环节。
            </div>
            <div class="paragraph_1" style="padding: 15px 0 30px;">
              引入领域建模后，我们开发出的软件由两层构成，如下图所示。下面一层是领域模型，它封装了深层领域知识，为各功能点的开发提供底层逻辑支持，是软件的灵魂。上面一层称为应用层，是很薄的一层，代码简单，开发容易且修改灵活。
            </div>
          </div>
          <img style="width: 100%; height: 300px;" src="@/assets/content.png" alt="">
          <div class="f-tist" style="padding-top: 30px;">3. 领域建模的原理</div>
          <div class="text-wrapper_28">
            <div class="paragraph_1">
              领域建模的要义在于：（一）化整为零，以业务活动的对象为单元拆解错综复杂的业务流程，应对业务复杂性挑战；（二）分离变与不变，从业务活动中分离出恒定不变的规律，应对需求多变性挑战。
            </div>
          </div>
          <div class="box_14 flex-row" @click="aliplayers(0)">
            <div class="block_6 flex-col">
              <img class="image_5" referrerpolicy="no-referrer"
                src="./assets/img/psf7t6r24x82fl4hii1tcwsytvt1gp0o1g77d70da3-cf52-47b4-b7ff-5f694bb059fd.png" />
            </div>
          </div>
          <div class="box_15 flex-row justify-between">
            <div class="section_7 flex-col" @click="aliplayers(1)">
              <div class="image-wrapper_3 flex-col">
                <img class="thumbnail_2" referrerpolicy="no-referrer"
                  src="./assets/img/psvklmupa72vhz9ruxvjhl195brlp1rfoko896648ff-d593-444e-90bb-3900ccb52c56.png" />
              </div>
            </div>
            <div class="section_8 flex-col" @click="aliplayers(2)">
              <div class="image-wrapper_4 flex-col">
                <img class="thumbnail_3" referrerpolicy="no-referrer"
                  src="./assets/img/ps1o1yk9ja7d3wzfqtmjnjxnt8zul4lcr8oa11dd512-6442-4237-a1a0-47d898b61e42.png" />
              </div>
            </div>
          </div>
          <div class="box_16 flex-row justify-between">
            <div class="text-wrapper_12 flex-col"><span class="text_21" @click="goTo('/experience')">免费体验</span></div>
            <div class="text-wrapper_13 flex-col"><span class="text_22" @click="goTo('/case')">查看成功案例</span></div>
          </div>
        </div>
      </div>
      <div class="block_7">
        <div class="text-wrapper_29">碎话领域建模</div>
        <div class="box_17 flex-row justify-between">
          <div class="section_10 flex-col" @click="aliplayers(3)">
            <div class="image-wrapper_5 flex-col">
              <img class="thumbnail_4" referrerpolicy="no-referrer"
                src="./assets/img/ps8wv6x0n8x7dsc5qog55yhh2felvdtgcch304ed8b4-7c49-490f-bb15-31007fbeaed5.png" />
            </div>
          </div>
          <div class="section_11 flex-col" @click="aliplayers(4)">
            <div class="image-wrapper_6 flex-col">
              <img class="thumbnail_5" referrerpolicy="no-referrer"
                src="./assets/img/ps4ie9qplf386x3fm1gbpn3b89mol43hovbfcc37693-397f-49a4-b4ce-45513c5e0b60.png" />
            </div>
          </div>
          <div class="section_12 flex-col" @click="aliplayers(5)">
            <div class="image-wrapper_7 flex-col">
              <img class="thumbnail_6" referrerpolicy="no-referrer"
                src="./assets/img/ps6mkn9dpd128vhitysq4qer7uza2bb56d7806534d-cc9b-4a26-bb2a-63c42e7f83fa.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose" width="1000px">
      <div class="prism-player" id="J_prismPlayer"></div>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      dialogVisible: false,
      title: "",
      player: null,
      playerList: [
        {
          title: "解密领域建模",
          url: "https://api.obase.vip/api/v1/video/decrypting-domain-modeling-new/aliyun-play-auths"
        },
        {
          title: "如何有效应对复杂性挑战",
          url: "https://api.obase.vip/api/v1/video/how-to-effectively-address-complexity-challenges/aliyun-play-auths"
        },
        {
          title: "如何有效应对多变性挑战",
          url: "https://api.obase.vip/api/v1/video/how-to-effectively-address-variability-challenges/aliyun-play-auths"
        },
        {
          title: "为什么烂尾项目这么多",
          url: "https://api.obase.vip/api/v1/video/why-are-there-so-many-unfinished-projects/aliyun-play-auths"
        },
        {
          title: "产品技术打架的底层原因",
          url: "https://api.obase.vip/api/v1/video/reasons-for-conflicts-between-product-and-technology-teams/aliyun-play-auths"
        },
        {
          title: "系统大量BUG的背后",
          url: "https://api.obase.vip/api/v1/video/the-root-causes-of-numerous-system-bugs/aliyun-play-auths"
        }
      ]
    };
  },
  methods: {
    // eslint-disable-next-line no-undef  
    aliplayers(index) {
      LA.track(index === 0 ? 'jm_06' : (index === 1 ? 'fz_07' : (index === 2 ? 'db_08' : (index === 3 ? 'lw_09' : (index === 4 ? 'dj_10' : 'bug_11')))));
      let _this = this;
      axios.get(_this.playerList[index].url)
        .then(res => {
          _this.dialogVisible = true;
          _this.title = _this.playerList[index].title;
          let data = res.data;
          setTimeout(() => {
            _this.player = new Aliplayer({
              id: "J_prismPlayer",
              autoplay: true,
              width: "960px",
              height: "540px",
              vid: data.data.videoId,
              playauth: data.data.playAuth,
              cover: data.data.coverURL,
              license: {
                domain: "obase.vip",
                key: "9PdeWqfeMBDmX0Vg7315f547fe2904a9e91d9b77be20421df"
              }
            });
          }, 120);
        })
    },
    handleClose() {
      if (this.player) {
        this.player.dispose();
      }
      this.dialogVisible = false;
    },
    goTo(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.f-tist {
  overflow-wrap: break-word;
  color: #EC6817;
  font-size: 20px;
  font-family: MiSans-Semibold;
  font-weight: 600;
  text-align: justifyLeft;
  white-space: nowrap;
  padding-top: 40px;
}

::v-deep .el-dialog__header {
  padding-bottom: 0;
}

::v-deep .el-dialog {
  border-radius: 10px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 28px;
  color: #333;
}

::v-deep .el-dialog__title {
  font-size: 22px;
  font-weight: 600;
}
</style>