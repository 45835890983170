<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_3 ">
        <div class="f-w1200 flex-col">
          <span class="text_9">团队成员</span>
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-row justify-between">
            <span class="text_10">创始人：</span>
            <div class="box_4 flex-row">
              <img class="image_2" referrerpolicy="no-referrer"
                src="./assets/img/psgksfjf87to6ajy3gtv7r77otcp21l8y25be0056b-d747-4fa7-84c5-68056da88c7f.png" />
              <div class="box_5 flex-col">
                <span class="text_11">陈&nbsp;&nbsp;瑞</span>
                <div class="text-wrapper_1 flex-col"><span class="text_12">首席科学家</span></div>
                <span class="text_13">
                  领域建模方法和Obase框架创始人，潜心研究领域建模方法和对象持久化技术20年，创立了完整的学术和技术体系，亲任系统设计师主导Obase框架的研发工作，并带领团队应用领域建模方法和Obase框架，开发了二十多个中、大型项目。
                </span>
              </div>
            </div>
          </div>
          <div class="group_6 flex-col"></div>
        <div class="group_7 flex-row">
          <span class="text_14">建模师：</span>
          <div class="group_8 flex-col">
            <span class="text_15">余邦强</span>
            <div class="image-text_1 flex-col justify-between">
              <div class="text-wrapper_2 flex-col"><span class="text_16">领域分析师</span></div>
              <span class="text-group_1">软件开发经验15年&nbsp;&nbsp;丨&nbsp;&nbsp;参与建模项目40+</span>
            </div>
            <div class="box_6 flex-col"></div>
            <span class="text_17">业务理解能力强，沟通非常愉快，已经是我们公司的技术合伙人了。</span>
            <div class="box_7 flex-col"></div>
            <span class="text_18">物联网、智慧工地、办公自动化、财务管理、电子商务</span>
            <span class="text_19">客户评价</span>
            <span class="text_20">擅长领域</span>
          </div>
          <div class="group_9 flex-col">
            <span class="text_21">程用岱</span>
            <div class="image-text_2 flex-col justify-between">
              <div class="text-wrapper_3 flex-col"><span class="text_22">领域分析师</span></div>
              <span class="text-group_2">软件开发经验10年&nbsp;&nbsp;丨&nbsp;&nbsp;参与建模项目30+</span>
            </div>
            <div class="block_1 flex-col"></div>
            <span class="text_23">概括能力强，能准确理解我们公司的业务并提炼出核心逻辑。项目交给他我很放心。</span>
            <div class="block_2 flex-col"></div>
            <span class="text_24">智能制造、供应链、决策支持、ERP、客户关系管理</span>
            <span class="text_25">客户评价</span>
            <span class="text_26">擅长领域</span>
          </div>
          <div class="group_10 flex-col">
            <span class="text_27">季荣燕</span>
            <div class="image-text_3 flex-col justify-between">
              <div class="text-wrapper_4 flex-col"><span class="text_28">领域分析师</span></div>
              <span class="text-group_3">软件开发经验5年&nbsp;&nbsp;丨&nbsp;&nbsp;参与建模项目10+</span>
            </div>
            <div class="group_11 flex-col"></div>
            <span class="text_29">业务分析专业、精准，沟通顺畅，能快速将复杂业务简单化表示。</span>
            <div class="group_12 flex-col"></div>
            <span class="text_30">教务信息化、金融票据交易、审批流、智能文档</span>
            <span class="text_31">客户评价</span>
            <span class="text_32">擅长领域</span>
          </div>
        </div>
        <div class="group_13 flex-row justify-between">
          <div class="section_1 flex-col">
            <span class="text_33">邓陈鑫</span>
            <div class="image-text_4 flex-col justify-between">
              <div class="text-wrapper_5 flex-col"><span class="text_34">领域分析师</span></div>
              <span class="text-group_4">软件开发经验7年&nbsp;&nbsp;丨&nbsp;&nbsp;参与建模项目20+</span>
            </div>
            <div class="group_14 flex-col"></div>
            <span class="text_35">沟通专业，始终站在我们客户角度挖掘软件的业务价值，考虑用户体验。</span>
            <div class="group_15 flex-col"></div>
            <span class="text_36">医疗信息化、司法信息化、在线教育</span>
            <span class="text_37">客户评价</span>
            <span class="text_38">擅长领域</span>
          </div>
          <div class="section_2 flex-col">
            <span class="text_39">黎雪林</span>
            <div class="image-text_5 flex-col justify-between">
              <div class="text-wrapper_6 flex-col"><span class="text_40">模型设计师</span></div>
              <span class="text-group_5">领域建模经验8年</span>
            </div>
            <div class="group_16 flex-col"></div>
            <span class="text_41">
              八年领域建模经验，精通面向对象理念与技术，熟练掌握各种设计模式和系统架构，成功设计20多个领域模型，运用到多个大型项目。
            </span>
            <span class="text_42">简介</span>
          </div>
        </div>
        <div class="group_17 flex-col"></div>
        <div class="group_18 flex-row">
          <span class="text_43">研究员：</span>
          <div class="box_8 flex-col">
            <span class="text_44">刘&nbsp;&nbsp;&nbsp;睿</span>
            <div class="image-text_6 flex-col justify-between">
              <div class="text-wrapper_7 flex-col"><span class="text_45">研究员</span></div>
              <span class="text-group_6">软件开发经验12年</span>
            </div>
            <div class="block_3 flex-col"></div>
            <span class="text_46">
              十二年软件开发经验，从事Obase框架研发工作五年，精通.NET和Java语言底层技术，熟悉UML建模，擅长大型复杂系统的底层技术研发。
            </span>
            <span class="text_47">简介</span>
          </div>
          <div class="box_9 flex-col">
            <span class="text_48">宋焱燚</span>
            <div class="image-text_7 flex-col justify-between">
              <div class="text-wrapper_8 flex-col"><span class="text_49">研究员</span></div>
              <span class="text-group_7">7年人工智能和大数据研发经验</span>
            </div>
            <div class="box_10 flex-col"></div>
            <span class="text_50">
              七年人工智能和大数据研发经验，从事Obase框架科研工作五年，精通人工智能和大数据技术，擅长相关算法研究，著《模型与算法基础》一书。
            </span>
            <span class="text_51">简介</span>
          </div>
        </div>
        </div>


       
      
        
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />